export default {
  siteTitle: 'Get bonus',
  blogerName: 'NOKAIN',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@nokainlive/videos'
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/nokainbet'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/nokainbr/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/cf8a83a46',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>NOKAIN</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'NOKAIN',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
